import {
	type LinkBehaviourOverrideOptions,
	linkBehaviourInjectKey,
} from '@ipr-marsproxies/design-system'
import type { Plugin } from 'vue'

export const linkBehaviourPlugin: Plugin = {
	install(app) {
		app.provide(linkBehaviourInjectKey, {
			getAttributes: (href?: string) => {
				if (!href) {
					return undefined
				}

				const formattedHref = href.trim()
				const isDataAttr =
					formattedHref.startsWith('[data-') && formattedHref.endsWith(']')

				if (!isDataAttr) {
					return undefined
				}

				const [dataAttr, value] = href
					.replace('[', '')
					.replace(']', '')
					.split('=')

				return {
					as: 'button',
					[dataAttr]: value,
				}
			},
		} satisfies LinkBehaviourOverrideOptions)
	},
}
